<template>
  <div class="flex flex-col self-stretch group-hover:mt-3">
    <div v-html="newcontent" class="flex items-center justify-center flex-1 mx-auto"></div>
    <div class="mb-2 text-sm text-center">{{ name }}</div>
  </div>
</template>

<script>
export default {
  //name: "Icon",
  data() {
    return {
      newcontent: String,
      ccolor: String
    };
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 44
    },
    color: {
      type: String,
      default: "#2c3e50"
    },
    stroke: {
      type: String,
      default: "1.5"
    }
  },
  watch: {
    ccolor(newValue) {
      this.update(newValue, "color");
    },
    color(newValue) {
      this.update(newValue, "color");
    },
    stroke(newValue) {
      this.update(newValue, "stroke");
    },
    size(newValue) {
      this.update(newValue, "size");
    }
  },
  mounted() {
    this.newcontent = this.content;
  },
  methods: {
    update(newValue, _type) {
      if (_type == "color") {
        if (/^#([0-9A-F]{6}){1}$/i.test(newValue) !== true) {
          //log("wrong val :( " + newValue);
          return;
        }
      }
      this.newcontent = this.content.replace(
        'stroke="currentColor"',
        'stroke="' + this.color + '"'
      );
      this.newcontent = this.newcontent.replace(
        'stroke-width="1.5"',
        'stroke-width="' + this.stroke + '"'
      );
      this.newcontent = this.newcontent.replace(
        'width="44"',
        'width="' + this.size + '"'
      );
      this.newcontent = this.newcontent.replace(
        'height="44"',
        'height="' + this.size + '"'
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
