import Vue from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import Clipboard from "v-clipboard";
//import Toasted from "vue-toasted";
/*import VueAnalytics from "vue-analytics";

Vue.use(VueAnalytics, {
  id: "UA-90704875-10",
});*/

//Vue.use(Toasted);
Vue.use(Clipboard);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
