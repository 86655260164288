<template>
  <div id="app">
    <hero :count="count"></hero>
    <div class="top-0 z-50 bg-gray-100" :class="{ sticky: sticky }">
      <div
        class="flex flex-col justify-around p-2 flex-no-shrink md:order-last md:flex-row"
      >
        <!-- Size -->
        <div
          class="flex p-2 mx-2 mt-2 bg-white border-white rounded-md shadow-md md:w-3/12 xl:w-4/12 md:mt-0"
        >
          <div class="self-center m-3 mr-4 text-left">Size</div>
          <div class="flex flex-1 mr-6">
            <vue-slider
              v-model="size"
              :data="data_size"
              value="24"
              class="self-center flex-1"
            ></vue-slider>
            <div class="self-center w-6 ml-4">{{ size }}px</div>
          </div>
        </div>
        <!-- Stroke -->
        <div
          class="flex p-2 mx-2 mt-3 bg-white border-white rounded-md shadow-md md:w-3/12 xl:w-4/12 md:mt-0"
        >
          <div class="self-center m-2 mr-4">Stroke</div>
          <div class="flex flex-1 mr-6">
            <vue-slider
              v-model="stroke"
              :marks="false"
              :data="data_stroke"
              class="self-center flex-1"
            ></vue-slider>
            <div class="self-center w-6 ml-4">{{ stroke }}px</div>
          </div>
        </div>
        <!-- Color -->
        <div
          class="flex justify-center p-2 mx-2 mt-3 bg-white border-white rounded-md shadow-md md:w-2/12 md:mt-0 xl:w-2/12"
        >
          <label for="color" class="self-center inline-block mr-2">Color</label>
          <input
            v-model="color"
            type="text"
            name="color"
            id="color"
            class="self-center w-24 h-10 p-0 text-center border-2 border-gray-400 rounded-md shadow-inner outline-none"
            v-bind:style="{ 'border-color': color }"
          />
        </div>
        <!-- Settings -->
        <div
          class="flex items-center justify-center p-2 mx-2 mt-3 bg-white border-white rounded-md shadow-md md:w-4/12 md:mt-0 xl:w-1/3"
        >
          <button
            @click="toggleSearch()"
            v-bind:class="{
              'bg-gray-100': svisible,
              'bg-gray-900': !svisible,
              'text-black': svisible,
              'text-white': !svisible,
            }"
            class="self-center w-24 h-10 px-2 mr-2 text-sm border-2 border-gray-900 rounded-md outline-none hover:text-gray-900 hover:bg-white md:mb-0 focus:outline-none"
          >
            <svg
              v-if="svisible"
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="9 11 12 14 20 6" />
              <path
                d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"
              />
            </svg>
            Search
          </button>
          <button
            @click="togglePalette()"
            v-bind:class="{
              'bg-gray-100': pvisible,
              'bg-gray-900': !pvisible,
              'text-black': pvisible,
              'text-white': !pvisible,
            }"
            class="self-center w-24 h-10 px-2 mr-2 text-sm border-2 border-gray-900 rounded-md outline-none hover:text-gray-900 hover:bg-white md:mb-0 focus:outline-none"
          >
            <svg
              v-if="pvisible"
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="9 11 12 14 20 6" />
              <path
                d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"
              />
            </svg>
            Colors
          </button>
          <button
            @click="reset()"
            class="self-center w-20 h-10 px-2 mr-2 text-sm text-white bg-gray-900 border-2 border-gray-900 rounded-md outline-none focus:outline-none hover:text-gray-900 hover:bg-white"
          >
            Reset
          </button>
          <button
            @click="toggleSticky()"
            v-bind:class="{
              'bg-gray-100': sticky,
              'bg-gray-900': !sticky,
              'text-black': sticky,
              'text-white': !sticky,
            }"
            class="self-center h-10 px-2 text-sm border-2 border-gray-900 rounded-md outline-none tooltip hover:text-gray-900 hover:bg-white md:mb-0 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-sticker"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M20 12l-2 .5a6 6 0 0 1 -6.5 -6.5l.5 -2l8 8" />
              <path d="M20 12a8 8 0 1 1 -8 -8" />
            </svg>
            <span class="tooltiptext">Sticky Toolbar</span>
          </button>
        </div>
      </div>
      <!-- Palette -->
      <div v-if="pvisible">
        <swatches
          v-model="color"
          inline
          background-color="#f7fafc"
          :colors="colors"
          show-border
        ></swatches>
      </div>
      <!-- Search -->
      <div
        v-if="svisible"
        class="flex flex-col justify-around p-4 group flex-no-shrink md:order-last md:flex-row"
      >
        <div
          class="flex w-full p-2 transition-colors duration-300 ease-in bg-white border-2 border-white rounded-md shadow-md focus-within:border-purple-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="self-center ml-2 icon icon-tabler icon-tabler-search"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="10" cy="10" r="7" />
            <line x1="21" y1="21" x2="15" y2="15" />
          </svg>
          <input
            v-model="namesearch"
            type="text"
            name="color"
            id="color"
            class="self-center w-56 w-full pl-2 my-2 ml-4 rounded-md outline-none h-100"
            :placeholder="searchtext"
          />
        </div>
      </div>
    </div>
    <List
      :size="size"
      :color="color"
      :stroke="stroke"
      :svgs="filteredItems"
      ref="list"
    />
    <Popup v-if="puvisible && 1 == 0"></Popup>
  </div>
</template>

<script>
import Hero from "./components/Hero.vue";
import List from "./components/List.vue";
import Popup from "./components/Popup.vue";
import VueSlider from "vue-slider-component";
import Swatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.min.css";
import json from "./icons.json";

export default {
  name: "App",
  components: {
    Hero,
    List,
    Popup,
    VueSlider,
    Swatches,
  },
  data() {
    return {
      json,
      count: "",
      namesearch: "",
      puvisible: true,
      svisible: true,
      pvisible: true,
      sticky: true,
      stroke: "1.5",
      size: 44,
      color: "#2c3e50",
      colors: [
        "#ff2825",
        "#fd0061",
        "#a905b6",
        "#6f32be",
        "#00abfb",
        "#00abfb",
        "#00bfd8",
        "#009988",
        "#00b341",
        "#7bc62d",
        "#c9de00",
        "#ffec00",
        "#ffbf00",
        "#ff9300",
        "#ff4500",
        "#7f5345",
        "#9e9e9e",
        "#597e8d",
        "#000000",
        "#ffffff",
      ],
      data_stroke: ["0.5", "1", "1.5", "2", "2.5", "3"],
      data_size: [
        12,
        16,
        20,
        24,
        28,
        32,
        36,
        40,
        44,
        48,
        52,
        56,
        60,
        64,
        68,
        72,
        76,
        80,
        84,
        88,
        92,
        96,
        100,
      ],
    };
  },
  methods: {
    reset() {
      this.color = "#2c3e50";
      this.size = 44;
      this.stroke = "1.5";
    },
    togglePalette() {
      this.pvisible = !this.pvisible;
    },
    toggleSearch() {
      this.svisible = !this.svisible;
    },
    toggleSticky() {
      this.sticky = !this.sticky;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  computed: {
    filteredItems() {
      var filtered = this.json.filter((item) => {
        return (
          item.filename.toLowerCase().indexOf(this.namesearch.toLowerCase()) >
            -1 ||
          item.tags.toLowerCase().indexOf(this.namesearch.toLowerCase()) > -1
        );
      });
      return filtered;
    },
    searchtext() {
      return "Search " + this.count + " icons";
    },
  },
  mounted() {
    this.count = String(this.json.length);
    var settingsObj = JSON.parse(localStorage.getItem("settingsObj"));
    this.sticky = !this.isMobile();
    if (settingsObj && Object.entries(settingsObj).length > 0) {
      //this.stroke = settingsObj.stroke;
      this.size = settingsObj.size;
      this.color = settingsObj.color;
      this.svisible = settingsObj.svisible;
      this.pvisible = settingsObj.pvisible;
      this.sticky = settingsObj.sticky;
      this.puvisible =
        typeof settingsObj.puvisible !== "undefined"
          ? settingsObj.puvisible
          : true;
    }

    this.$watch(
      (vm) => [
        vm.stroke,
        vm.size,
        vm.color,
        vm.svisible,
        vm.pvisible,
        vm.sticky,
        vm.puvisible,
      ],
      () => {
        var settingsObj = {
          stroke: this.stroke,
          color: this.color,
          size: this.size,
          svisible: this.svisible,
          pvisible: this.pvisible,
          sticky: this.sticky,
          puvisible: this.puvisible,
        };
        localStorage.setItem("settingsObj", JSON.stringify(settingsObj));
      }
    );
  },
};
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #222f3d;

/* import theme style */
@import "~vue-slider-component/lib/theme/default.scss";
html {
  box-sizing: border-box;
}
*,
*:before,
*.afrer {
  box-sizing: inherit;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 1px;
  background-color: "#f7fafc";
  min-height: 100vw;
}
.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 110px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  left: -55px;
  top: -35px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
