<template>
  <div class="flex items-center justify-center">
    <a
      class="relative flex flex-col items-center justify-center max-w-md p-4 px-6 mt-6 duration-200 bg-white border-2 border-dashed rounded-lg cursor-pointer hover:border-gray-400 hover:bg-gray-800 hover:text-white tranform"
      href="https://sqlzap.com"
    >
      <span class="absolute left-0 flex w-3 h-3 ml-3 pointer-events-none top-2">
        <span
          class="absolute inline-flex w-full h-full bg-pink-400 rounded-full opacity-75 animate-ping"
        ></span>
        <span
          class="relative inline-flex w-3 h-3 bg-pink-500 rounded-full"
        ></span>
      </span>
      <!-- <h1 class="block pl-4 text-lg font-bold">
          Free Invoice Generator
      </h1>
      <p class="block px-6">
        Are you a freelancer? Do you want to create invoices for your clients? Try this free privacy based <span class="text-blue-600 underline"> invoice generator</span>.
      </p> -->
      <h1 class="block pl-4 text-lg font-bold">Learn SQL for Free</h1>
      <p class="block px-6">
        Learn SQL fundamentals for free with our interactive
        <span class="text-blue-600 underline">sql basics</span> course.
      </p>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ping {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  border: 50px solid #222;
  width: 20px;
  height: 20px;
  animation: load 1.5s ease-out infinite;
}

@keyframes load {
  0% {
    background: #fff;
    border: 0px solid #fff;
  }
  50% {
    background: #fff;
    border: 100px solid #222;
  }
  100% {
    background: #fff;
    border: 0px solid #222;
  }
}
</style>
