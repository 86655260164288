<template>
  <div
    class="fixed bottom-0 right-0 flex justify-center md:justify-end"
    v-if="visible"
  >
    <div
      @click="visit"
      class="flex p-2 mb-12 mr-4 transition-colors duration-300 ease-in bg-white border-4 border-white border-purple-300 rounded-full shadow-lg cursor-pointer md:mb-3 shadow-purple-lg hover:border-purple-600 hover:shadow-none"
    >
      <!-- <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        class="self-center"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20"
            fill="#DA552F"
          />
          <path
            d="M22.667 20H17v-6h5.667a3 3 0 0 1 0 6m0-10H13v20h4v-6h5.667a7 7 0 1 0 0-14"
            fill="#FFF"
          />
        </g>
      </svg>-->
      <img
        src="https://pbs.twimg.com/profile_images/1349436315314622464/H2muCKsL_reasonably_small.jpg"
        class="w-12 h-12 rounded-full"
        alt="Csaba Kissi"
      />
      <div class="flex flex-col ml-2">
        <span class="block pb-1 mx-3 text-sm font-medium"
          >Follow me on Twitter</span
        >
        <div class="flex justify-center block text-sm">
          <div
            class="flex justify-center w-32 px-4 py-1 text-white bg-blue-400 rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="self-center mr-2"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path
                d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z"
              />
            </svg>
            <span>Follow me</span>
          </div>
        </div>
      </div>
      <div class="flex items-center ml-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          @click.stop="closeit"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <circle cx="12" cy="12" r="9" />
          <path d="M10 10l4 4m0 -4l-4 4" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    closeit() {
      this.visible = false;
      this.$parent.puvisible = false;
    },
    visit() {
      document.location.href =
        "https://twitter.com/intent/follow?original_referer=https://tablericons.com&screen_name=csaba_kissi";
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
