<template>
  <div
    class="flex flex-col flex-wrap p-2 font-sans bg-gray-100 md:content-between tems-stretch md:flex-row"
  >
    <div
      v-for="svg in svgs"
      :key="svg.filename"
      :ref="svg.filename"
      class="relative flex justify-center flex-1 h-40 p-2 m-2 text-center text-justify transition-colors duration-300 ease-in border-4 border-white rounded-md cursor-pointer group shadow-purple-lg hover:border-purple-600 hover:shadow-none"
      :class="[color.toLowerCase() == '#ffffff' ? 'bg-gray-400' : 'bg-white']"
      style="min-width:175px"
      @click="copy(svg.filename)"
    >
      <!-- <div class="absolute left-0 flex justify-center hidden w-full group-hover:flex">
        <a href="" class="px-2 mx-1 text-xs text-white transition-all duration-100 ease-in bg-purple-500 rounded-l-full rounded-r-full">PNG</a>
        <a href="" class="px-2 mx-1 text-xs text-white transition-all duration-100 ease-in bg-purple-500 rounded-l-full rounded-r-full">REACT/VUE</a>      
      </div> -->
      <icon
        :ref="`${svg.filename}-icon`"
        :name="svg.filename"
        :content="svg.content"
        :color="color"
        :stroke="stroke"
        :size="size"
      />
    </div>
  </div>
</template>

<script>
import icon from "./Icon";

export default {
  name: "Index",
  data() {
    return {};
  },
  props: {
    svgs: Array,
    size: Number,
    stroke: String,
    color: String,
  },
  components: {
    icon,
  },
  methods: {
    copy(name) {
      this.$clipboard(this.$refs[name + "-icon"][0].newcontent.trim());
      /*this.$toasted.show("Copied to clipboard", {
        duration: 1500,
        position: "bottom-center",
        singleton: true,
        className: ["bg-purple-600"],
      });*/
      var oldname = this.$refs[name + "-icon"][0].name;
      this.$refs[name + "-icon"][0].name = "🎉 copied";
      setTimeout(() => {
        this.$refs[name + "-icon"][0].name = oldname;
      }, 2000);
    },
  },
  updated() {
    this.svgs.forEach((element) => {
      var elname = `${element.filename}-icon`;
      if (typeof this.$refs[elname][0] == "object") {
        this.$refs[elname][0].$data.ccolor = this.color;
      }
    });
  },
  created() {
    // let el = document.createElement("script");
    // el.setAttribute("src", "//static.getclicky.com/js");
    // document.head.appendChild(el);
    // el = document.createElement("script");
    // el.text =
    //   "var clicky_site_ids = clicky_site_ids || []; clicky_site_ids.push(101250923);";
    // document.head.appendChild(el);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.toasted-container.top-right {
  top: 10px;
}
</style>
